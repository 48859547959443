const theme = {
  colors: {
    white: "#fff",
    black: "#000000",
    grey: "#e5e5e5",
    veryLightGrey: "#f7f7f7",

    green: "#acb7ae",
    taupe: "#cfbcb3",
    lightGrey: "#f7f8f8",
  },
  fonts: {
    body: '"Inter var",-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    grotta: '"Grotta Light", sans-serif',
  },
  fontWeights: {
    extraLight: 100,
    light: 200,
    normal: 400,
    semiBold: 500,
    bold: 600,
  },
  lineHeights: {
    bodyCopy: 1.6,
    heading: 1.125,
  },
  letterSpacings: {},
  fontSizes: {
    bodyCopy: "0.9em",
  },
  space: [],
  sizes: {
    maxWidth: "1420px",
  },
  responsive: {
    small: "35em",
    medium: "50em",
    large: "80em",
  },
  animation: {
    easing: "cubic-bezier(0.5, 0.7, 0.4, 1)",
  },
};

export default theme;
